<template>
    <div class="page-nav">
        <div class="page-nav-user">
          <b-button :disabled="!canEmit" variant="primary" size="sm" @click="emitNewVersion()">Emit new version</b-button>
        </div>
    </div>
</template>
<script>
import CallstrLive from '@/common/services/callstrLiveObject';
export default {
    props: ['user'],
    components: {

    },
    data(){
      return {
        canEmit: true
      }
    },
    methods: {
      emitNewVersion(){
        this.canEmit = false;
        let signal = CallstrLive();

        signal.on('connect', () => {
          alert("New version was emitted to all users!");
          signal.emit('new-version',{newVersion: true});
          signal.disconnect();
          this.canEmit = true;
        })
      }
    }
}
</script>
