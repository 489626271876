import Users from '@/modules/users';
import Emails from '@/modules/emails';
import Translations from '@/modules/translations';
import Products from '@/modules/products';

export default{
    modules: {
        Users: Users,
        Emails: Emails,
        Translations: Translations,
        Products: Products,
    }
}
