<template>
    <div class="sidebar">
      <div class="sidebar-logo">
          <b-link class="sidebar-brand" :to='{name: "app.dashboard"}'>
              <img src="@/common/assets/img/logo.png" class="sidebar-logo-img">
              <span class="sidebar-logo-text">
                  <span class="sidebar-logo-text-small">administration</span><br>
                  <span class="sidebar-logo-text-big">CALLSTR</span>
              </span>
          </b-link>
      </div>
      <div class="sidebar-nav">
          <ul>
              <li v-if="user.hasPermission(['rtc:statistics','users:statistics'])">
                  <b-link v-bind:class="setActiveSidebar('dashboard')" :to='{name: "app.dashboard"}'>
                    <BIconBarChartLine class="float-right mt-1 text-muted"/>
                    Statistics
                  </b-link>
              </li>
              <li v-if="user.hasPermission('users:user.search')">
                  <b-link v-bind:class="setActiveSidebar('users')" :to='{name: "users"}'>
                    <BIconPeople class="float-right mt-1 text-muted"/>
                    User Management
                  </b-link>
              </li>
              <!--
              <li v-if="user.hasPermission('products:product.search')">
                  <b-link v-bind:class="setActiveSidebar('products')" :to='{name: "products"}'>
                    <BIconCart2 class="float-right mt-1 text-muted"/>
                    Product Management
                  </b-link>
              </li>
              <li v-if="user.hasPermission('translations:language.search')">
                  <b-link disabled v-bind:class="setActiveSidebar('translations')" :to='{name: "translations"}'>
                    <BIconGlobe class="float-right mt-1 text-muted"/>
                    Translation Management
                  </b-link>
              </li>
            -->
              <li v-if="user.hasPermission('emails:emails.search')">
                  <b-link v-bind:class="setActiveSidebar('emails')" :to='{name: "emails"}'>
                    <BIconEnvelope class="float-right mt-1 text-muted"/>
                    E-Mail Management
                  </b-link>
              </li>
          </ul>
      </div>
    </div>
</template>
<script>
import {
  BIconBarChartLine,BIconPeople,BIconEnvelope,
  //BIconGlobe,BIconCart2
} from 'bootstrap-vue';
export default {
    props: ['user','sidebar'],
    components: {
      BIconBarChartLine,BIconPeople,BIconEnvelope,
      //BIconCart2,BIconGlobe
    },
    methods: {
      setActiveSidebar(name){
          if(!this.sidebar){
              return '';
          }
          return this.sidebar.active === name ? 'active' : '';
      }
    }
}
</script>
