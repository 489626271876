export default [
  {
      path: '/users',
      name: 'users',
      component: () => import('../../views/Users/Users.vue')
  },
  {
      path: '/users/user/:userId',
      name: 'users.user.details',
      component: () => import('../../views/Users/User.Details.vue')
  },
  {
      path: '/users/roles',
      name: 'users.roles',
      component: () => import('../../views/Roles/Roles.vue')
  },
  {
      path: '/users/roles/:roleId',
      name: 'users.roles.role',
      component: () => import('../../views/Roles/Role.Details.vue')
  },
  {
      path: '/users/permissions',
      name: 'users.permissions',
      component: () => import('../../views/Permissions/Permissions.vue')
  },
  {
      path: '/users/permissions/:permissionId',
      name: 'users.permissions.permission',
      component: () => import('../../views/Permissions/Permission.Details.vue')
  },
  {
      path: '/users/permissions/bulk-create',
      name: 'users.permissions.bulkCreate',
      component: () => import('../../views/Permissions/Permission.CreateBulk.vue')
  }
]
