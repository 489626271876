var routes = [
    {
        path: '/emails',
        name: 'emails',
        component: () => import('../../views/Emails.vue')
    },
    {
        path: '/emails/view/:emailId',
        name: 'emails.email.details',
        component: () => import('../../views/Email.Details.vue')
    },
    {
        path: '/emails/settings',
        name: 'emails.settings',
        component: () => import('../../views/Settings.vue')
    },
    {
        path: '/emails/templates',
        name: 'emails.templates',
        component: () => import('../../views/Templates/Templates.vue')
    },
    {
        path: '/emails/templates/:templateId',
        name: 'emails.templates.template',
        component: () => import('../../views/Templates/Template.View.vue')
    },
    {
        path: '/emails/templates/:templateId/:locale',
        name: 'emails.templates.template.translation',
        component: () => import('../../views/Templates/Template.Translation.Edit.vue')
    },
];

export default routes;
