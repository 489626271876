import io from 'socket.io-client';
import authstorage from '@/common/services/authstorage';
import cookies from '@/common/services/cookies';
const authStorage = authstorage('cookies');
const cookieStorage = cookies('cookies');

export default function(){
  let accessToken = authStorage.get();
  if(!accessToken){
    return;
  }

  let signal = io(process.env.VUE_APP_CALLSTR_LIVE_HOST,{
      transports: ['websocket','polling'],
      secure: true,
      query: {
        accessToken: accessToken,
        csrfToken: cookieStorage.get('_callstr_csrf')
      }
  });
  
  return signal;
}
