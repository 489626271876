var routes = [
    {
        path: '/translations',
        name: 'translations',
        component: () => import('../../views/Languages.vue')
    },
    {
        path: '/translations/:locale/collections',
        name: 'translations.collections',
        component: () => import('../../views/Collections.vue')
    }
];

export default routes;
