var routes = [
    {
        path: '/products',
        name: 'products',
        component: () => import('../../views/products/Products.vue')
    },
    {
        path: '/products/create',
        name: 'products.product.create',
        component: () => import('../../views/products/Product.Create.vue')
    },
    {
        path: '/products/packages',
        name: 'products.packages',
        component: () => import('../../views/packages/Packages.vue')
    },
    {
        path: '/products/packages/create',
        name: 'products.packages.package.create',
        component: () => import('../../views/packages/Package.Create.vue')
    },
    {
        path: '/products/packages/:packageId',
        name: 'products.packages.package.edit',
        component: () => import('../../views/packages/Package.Edit.vue')
    },
    {
        path: '/products/:productId',
        name: 'products.product.edit',
        component: () => import('../../views/products/Product.Edit.vue')
    },
];

export default routes;
