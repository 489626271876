<template>
    <div id="app">
        <Sidebar :sidebar="sidebar" :user="user"/>
        <div class="page-container">
            <Navbar :navbar="navbar" :user="user"/>
            <div class="page-content">
                <router-view :user="user" @set-sidebar="setSidebar" @set-navbar="setNavbar" />
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/common/components/Sidebar';
import Navbar from '@/common/components/Navbar';
export default {
    props: ['user'],
    components: {
      Sidebar,
      Navbar
    },

    data(){
      return {
        sidebar: null,
        navbar: null
      };
    },

    methods: {
      setSidebar(data){
        this.sidebar = data;
      },

      setNavbar(data){
          this.navbar = data;
      },

    },

    mounted(){

    }
}
</script>
