import users from './users';
var routes = [
    {
        path: '/login',
        name: 'users.login',
        component: () => import('../../views/Login.vue')
    }
];

export default routes
  .concat(users);
