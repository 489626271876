import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import NodeSDKAPI from 'nodesdk-api-wrapper';
//import CallstrLive from '@/common/services/callstrLive';

Vue.config.productionTip = false;

import { BootstrapVue} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/common/assets/css/style.css';

Vue.use(BootstrapVue);

Vue.use(NodeSDKAPI.Vue({
    name: '$api',
    host: process.env.VUE_APP_BASE_API_HOST
}));

//Vue.use(CallstrLive);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
